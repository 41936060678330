import "./Footer.css";
import { Container, Row, Col } from "react-bootstrap";

function ShringarFooter() {
  return (
    <Container fluid className="footer" id="contact">
      <Row>
        <Col className="text-center" xs={12} sm={12} md={12} lg={6} xl={6}>
          <h1 className="titleFooter">Hours of Operation</h1>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">Monday - Thursday</p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">10:30AM - 7:00PM</p>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">Friday</p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">10:00AM - 5:00PM</p>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">Saturday</p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">10:00AM - 2:00PM</p>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">Sunday</p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <p className="hours">9:00AM - 12:00PM</p>
            </Col>
          </Row>
        </Col>
        <Col
          className="text-center footerContact"
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
        >
          <h1 className="titleFooter">Contact</h1>
          <h5>Call me now to book your appointment at Shringar!</h5>
          <br />
          <p>Phone: 587-578-0835</p>
        </Col>
      </Row>
      <Row>
        <p className="text-center CodeSignAttr">
          This website was created by{"  "}
          <a
            style={{ color: "white", letterSpacing: "2.5px" }}
            href="https://divgoyal.me"
            target="_blank"
            rel="noreferrer"
          >
            <b>Div Goyal</b>
          </a>
          {"  "} and{"  "}
          <a
            style={{ color: "white", letterSpacing: "2.5px" }}
            href="https://www.linkedin.com/in/nishant-sr/"
            target="_blank"
            rel="noreferrer"
          >
            <b>Nishant Srinivasan</b>
          </a>
        </p>
      </Row>
    </Container>
  );
}

export default ShringarFooter;
