import "./Services.css";
import { Container, Row, Col } from "react-bootstrap";

function Services() {
  return (
    <Container fluid className="services" id="services">
      <Row>
        <h1 className="text-center servicesTitle">List of Services</h1>
      </Row>
      <Row className="servicesList">
        <Col xl={4} lg={4} md={12} sm={12} xs={12}>
          <h2 className="text-center">Hair</h2>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Eyebrows</p>
              <p className="text-center">(Eyebrows tinting)</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Call/Text for Price</p>
              <p className="text-center">($20-$25)</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Upper Lips</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$3</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Chin</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$3-$5</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Forehead</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$3</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Full Face with Threading</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$20</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Haircut for Girls</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$20+</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Ladies' Haircut</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$20-$30</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Henna on Ladies' Hair</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$20-$25</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Henna on Hands</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$5-$15</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Hair Color for Ladies</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">
                (If you have your own product $25+). If I use my own
                professional color, $55-60
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Blow-Dry</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$30</p>
            </Col>
          </Row>
        </Col>
        <Col lg={4} sm={12} xs={12}>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Hot-Tool Styling</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Ask for price</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Highlights on Hair</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$160+</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Keratin Treatment on Hair</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">From $120</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Hair-Spa</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$50</p>
            </Col>
          </Row>
          <hr />
          <h2 className="text-center">Wax</h2>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Waxing Full Legs</p>
              <p className="text-center">(Half a Leg)</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$40</p>
              <p className="text-center">($24)</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Full Hands</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$25+</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Under-Arms</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$10-$15</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Bikini</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$10</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Brazilian</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$35+</p>
            </Col>
          </Row>
          <hr />
          <h2 className="text-center">Facial</h2>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Facial Herbal</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$50</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Facial Lotus</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$55+</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Facial Pearl</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$55+</p>
            </Col>
          </Row>
        </Col>
        <Col lg={4} sm={12} xs={12}>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Facial Aroma</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$55+</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">
                Facial Shahnaz Gold (Back Massage Free)
              </p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$60-$65</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">
                Facial Shahnaz Diamond (Back Massage Free)
              </p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$60-$70</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Microdermabrasion</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$65+</p>
            </Col>
          </Row>
          <hr />
          <h2 className="text-center">Nails</h2>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Manicure</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$25</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Pedicure</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$30</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Polish Change</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$5</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Colour Gloss Polish</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$15</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Gel Nails</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">From $45</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Shellack Nails for Hands</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$25</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Toes</p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">$20</p>
            </Col>
          </Row>
          <hr />
          <h2 className="text-center">Party Makeup</h2>
          <Row>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">
                Variety of Party Makeup - Bridal Makeup, Hair-Updo, Western or
                Traditional Updo, Saree Put-On
              </p>
            </Col>
            <Col lg={6} sm={6} xs={6}>
              <p className="text-center">Ask For Price</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Services;
