import "./About.css";
import { Container } from "react-bootstrap";

function About() {
  return (
    <Container fluid className="aboutParent">
      <Container fluid className="about" id="about">
        <h1 className="aboutTitle">About Shringar</h1>
        <p className="aboutText">
          Shringar is privately owned and operated by me (Dimple Shah). I've
          been in the industry for well over 25 years, beginning my journey in
          India where I started a salon. I then moved to Canada and opened up my
          own parlor and got the license for a salon. To further solidify my
          skills as a nail technician, bridal makeup artist, and ladies' hair
          highlights expert, I went to Beauty school. I love seeing my clients
          happy and feeling good after their appointment with me!
        </p>
      </Container>
    </Container>
  );
}

export default About;
