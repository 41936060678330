import "./App.css";
import ShringarNavbar from "./Components/ShringarNavbar";
import Services from "./Components/Services";
import Reviews from "./Components/Reviews";
import About from "./Components/About";
import Landing from "./Components/Landing";
import ShringarFooter from "./Components/Footer";
//import Gallery from "./Components/Gallery";

function App() {
  return (
    <>
      <ShringarNavbar />
      <Landing />
      <About />
      <Reviews />
      <Services />
      <ShringarFooter />
    </>
  );
}

export default App;
