import "./Reviews.css";
import { Container, Carousel } from "react-bootstrap";
import Review1 from "./Images/1.jpg";
import Review2 from "./Images/2.jpg";
import Review3 from "./Images/3.jpg";

function Reviews() {
  return (
    <Container fluid className="reviews" id="reviews">
      <Carousel className="text-center" indicators={false} controls={false}>
        <Carousel.Item>
          <img src={Review1} className="reviewTile" alt="First Review" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Review2} className="reviewTile" alt="Second Review" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Review3} className="reviewTile" alt="Third Review" />
        </Carousel.Item>
      </Carousel>
    </Container>
  );
}

export default Reviews;
