import "./Landing.css";
import { Container, Row, Col } from "react-bootstrap";
import LandingImage from "./Images/4circles.png";

function Landing() {
  return (
    <Container fluid className="landing" id="landing">
      <Row>
        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
          <div className="landingText">
            <h1 className="title text-center">Shringar</h1>
            <br />
            <h1 className="bio text-center">Beauty Parlor</h1>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <img
            src={LandingImage}
            className="landingImg"
            alt="Landing"
            width={"100%"}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}></Col>
      </Row>
    </Container>
  );
}

export default Landing;
